<template>
  <div>
    <counter />
    <div class="row">
      <div class="col-12">
        <v-card
          :elevation="0"
          class="ma-2"
          color="grey-lighten-4"
          flat
          min-height="200px"
          outlined
          v-for="c in campaigns"
          :key="c.id"
        >
          <v-toolbar color="primary" :dark="true">
            <v-toolbar-title>
              {{ c.name }}
              <br />
              <span>
                {{ c.campaign_details.vp.administrative_region }}
              </span>
              <!--<v-btn
                      class="ma-2"
                      color="green"
                      @click="getCampaignDetails(c)"
                    >
                      <v-icon
                        icon="mdi mdi-view-dashboard-variant-outline"
                      ></v-icon>
                      View Details
                    </v-btn>-->
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-title class="hidden-sm-and-down">
              {{ c.start_date }} - {{ c.end_date }}
            </v-toolbar-title>
          </v-toolbar>
          <!--<v-card-text class="pa-3">
                  <v-progress-linear
                    :value="voter_progress"
                    color="green"
                    height="25"
                  >
                    <template v-slot:default="{ value }">
                      <strong>VOTER PROGRESS: {{ Math.ceil(value) }}%</strong>
                    </template>
                  </v-progress-linear>
                  <div class="col-12 text-center">
                    <strong>
                      Total {{ c.voters }} Assigned /
                      {{ getDays(new Date(c.end_date), new Date()) }} Days
                      Remaining
                    </strong>
                  </div>
                </v-card-text>-->
          <v-card-text>
            <div>
              <campaign-details :campaign="c" :data="c.campaign_details" />
            </div>
          </v-card-text>
        </v-card>
      </div>
      <v-overlay :value="loading" :opacity="0.7">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>
<script>
//import ImageField from "@/components/custom/ImageField.vue";
import campaignDetails from "./campaignDetails.vue";
import counter from "./counter.vue";
//import dashboard from "./dashboard.vue";
import Vue from "vue";
import { Button, Card, Timeline, TimelineItem } from "element-ui";
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Card);
Vue.use(Button);
import Swal from "sweetalert2";
export default {
  watch: {},
  created() {
    this.getDashboard();
  },
  components: {
    counter,
    campaignDetails,
  },
  data() {
    return {
      valid: true,
      loading: false,
      voter_progress: 25,
      campaigns_id: null,
      news: [],
      campaigns: [],
      member: [],
      campaign_details: null,
      permissions: [],
      messages: [
        {
          from: "You",
          message: `Sure, I'll see you later.`,
          time: "11:42am",
          color: "deep-purple-lighten-1",
        },
        {
          from: "John Doe",
          message: "Yeah, sure. Does 1:00pm work?",
          time: "10:37am",
          color: "green",
        },
        {
          from: "You",
          message: "Did you still want to grab lunch today?",
          time: "9:47am",
          color: "deep-purple-lighten-1",
        },
      ],
    };
  },
  methods: {
    getDashboard() {
      this.loading = true;
      let f = {
        email: this.email,
        password: this.password,
        id_card_no: this.id_card_no,
        mobile_no: this.mobile_no,
      };
      this.api("public/vp-dashboard", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.news = response.news;
            this.campaigns = response.campaigns;
            if (this.campaigns.length == 1) {
              this.campaigns_id = this.campaigns[0].id;
            }
          } else {
            Swal.fire({
              title: "ACCESS",
              text: "You are not a VP, you will be directed to Home",
              type: "question",
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonColor: "#5daf34",
              cancelButtonColor: "#d33",
              showCancelButton: false,
              confirmButtonText: "OKAY",
            }).then((result) => {
              if (result.value) {
                document.location.href = "/";
              }
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  name: "VPHome",
  computed: {
    enterInteractions() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Enter Interactions")
            .length > 0
        : false;
    },
    exportExcel() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "xport Excel").length >
            0
        : false;
    },
    exportPDF() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Export PDF").length >
            0
        : false;
    },
    showContactNumbers() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Show Contact Numbers")
            .length > 0
        : false;
    },
    showPhotos() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Show Photos").length >
            0
        : false;
    },
    showVoteStatus() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Show Vote Status")
            .length > 0
        : false;
    },
    showDashboard() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Show Dashboard")
            .length > 0
        : false;
    },
  },
};
</script>
